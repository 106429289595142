import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import NavBar from "./NavBar";
import { StaticImage } from "gatsby-plugin-image";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import ReactPlayer from "react-player";
import { getPageData } from "../data/pageData";

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/"; // Check if it's the homepage

  const { backgroundImage, heading, description } = getPageData(location.pathname);

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: !isHomePage ? `url(${backgroundImage})` : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Background video for homepage only */}
        {isHomePage && (
          <div className="absolute inset-0 overflow-hidden">
            <ReactPlayer
              url="/iah-home-video.webm"
              playing
              loop
              muted
              width="100%"
              height="100%"
              className="home-video"
            />
          </div>
        )}

        {/* Overlay */}
        <div className="absolute inset-0 bg-gray-900 opacity-60"></div>

        <section className="relative z-10" id="inline-navbar">
          <div className="relative container mx-auto flex items-center justify-between py-10 px-6">
            <div className="flex items-center">
              <Link className="flex items-center" to="/">
                <StaticImage
                  src="../images/IAH2025-web-2xlogos.png"
                  alt="IAH 2025 logo"
                  width={600}
                  layout="constrained"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                />
              </Link>
            </div>
            <NavBar />
          </div>
        </section>

        <section className="relative z-10 py-20">
          <div className="container mx-auto grid grid-cols-1 gap-12 px-6">
            <div className="space-y-6">
              <h1 className="text-4xl font-bold leading-tight text-white sm:text-5xl md:text-6xl">
                {heading}
              </h1>
              <p className="text-white text-lg">{description}</p>
            </div>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
