import React from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "@/components/ui/sheet"
  import { CalendarRange } from "lucide-react";
  import useGoogleSheetData from "@/components/useGoogleSheet";
  import UserForm from '@/components/form/EOI-Form';
  import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "@/components/ui/dialog"
  import { Button } from "@/components/ui/button";

  export function KeyDatesTrigger() {

    const { data: data, loading, error } = useGoogleSheetData(
        "1ogCqfKqpgSO4PHroCPQDwJaNV4d7iZ4mAkXRnavQbGI",
        "Key Dates"
      );

    return (
        <>
            <Sheet>
                <SheetTrigger className="fixed bottom-5 left-5 rounded-lg py-3 px-6 text-xl font-bold drop-shadow-lg hover:drop-shadow-xl flex items-center gap-2 uppercase bg-primary text-white z-50"><CalendarRange />Key Dates</SheetTrigger>
                <SheetContent side={"left"}>
                    <SheetHeader>
                        <SheetTitle className="mb-0 pb-0">Key Dates</SheetTitle>
                        <SheetDescription>
                        {error && <div>Error: {error.message}</div>}
                            {!loading && !error && data[0].data.map((item, index) => (
                            <div key={index} className="border-b">
                                <h3 className="text-xl pt-2 text-secondary">{item.title}</h3>
                                <p className="text-base font-semibold text-black">{item.date}</p>
                            </div>
                        ))}
                        </SheetDescription>
                    </SheetHeader>
                </SheetContent>
            </Sheet>
        </>
      
    )
  }
  

  export function KeyDatesSideBar() {
    
  const eventName = 'IAH 2025';

    const { data: data, loading, error } = useGoogleSheetData(
        "1ogCqfKqpgSO4PHroCPQDwJaNV4d7iZ4mAkXRnavQbGI",
        "Key Dates"
      );

    return (
        <>
            <h2 className="mb-0">Key Dates</h2>
            {error && <div>Error: {error.message}</div>}
                {!loading && !error && data[0].data.map((item, index) => (
                <div key={index} className="border-b border-slate-300 py-2">
                    <h3 className="text-xl text-secondary">{item.title}</h3>
                    <p className="text-base font-semibold text-black">{item.date}</p>
                </div>
            ))}
            <Dialog>
                <DialogTrigger className="mt-4" asChild><Button className="w-full text-lg">Join the Mailing List</Button></DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="text-2xl">Join the Mailing List</DialogTitle>
                    </DialogHeader>
                    <UserForm eventName={eventName} />
                </DialogContent>
            </Dialog>
        </>

    )
  }