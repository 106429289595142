import React from 'react';
import { useLocation } from '@reach/router';
import Header from './header';
import Footer from './footer';
import BackToTop from './BackToTop';
import { KeyDatesSideBar } from './KeyDates';

export default function Layout({ children }) {
  const location = useLocation();
  const { pathname } = location;

  const noSidebarPages = ['/program-overview/', '/social-functions/'];
  const customPages = ['/congress-venue/'];

  const isNoSidebar = noSidebarPages.includes(pathname);
  const isCustomPage = customPages.includes(pathname);

  return (
    <>
      <Header />

      <main
        className={`mx-auto flex flex-col xl:flex-row ${
          isCustomPage || isNoSidebar ? '' : 'xl:container lg:gap-6'
        }`}
      >
        <div
          className={`relative w-full ${
            isNoSidebar ? '' : 'xl:w-9/12'
          } ${!isCustomPage ? 'py-12 md:py-16 lg:py-20 space-y-10' : ''}`}
        >
          {children}
        </div>

        {!isNoSidebar && (
          <div className="w-full xl:w-3/12 bg-slate-200 py-12 md:py-16 lg:py-20 px-5">
            <div className="sticky top-10">
              <KeyDatesSideBar />
            </div>
          </div>
        )}
      </main>

      <Footer />
      <BackToTop />
    </>
  );
}